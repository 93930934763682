.mnt-radio-cnt-data {
	margin-top: 10px;
}
.ant-input-suffix{
	color:var(--color2);
	font-weight: bold;
}
.boxBorder{
    margin: 50px 0px;
}
.ant-form-item-explain-error {
	color: var(--color8) ;
	font-size: 12px;
}

.gstEntry + .gstEntry {
	margin-top: 36px;
}

.gstEntry{
	margin-top: 15px;
}

.scrollBar {
	width: 100%;
    height: 630px;
    overflow-x: hidden;
    overflow-y: auto;
}
.ant-input:focus, .ant-input-affix-wrapper:focus{
	box-shadow:none !important;
}

.boxAnchor {
	border-radius: 40px;
	width: 40px;
	height: 40px;
	background-Color: #fff;
	color: #3d44e0;
	text-Align: center;
	text-transform: capitalize;
	padding-top: 9px;
	cursor: pointer;
	box-shadow: 1px 2px 5px rgb(0 0 0 / 20%);
}

.boxAnchor:hover {
    background-color: #3d44e0;
    color: #FFF;
}
.motion-disabled .ant-result-icon{
	display: none;
}
.motion-disabled .ant-result{
	padding-top: 0px;
}
.box .ant-result{
	padding-bottom: 0px;
}
