:root {
  --color1: #03064D;/*blue*/
  --color2: #011AF6;/*light blue*/
  --color3: #27DE8E;/*green*/
  --color4: #B3B4C9;/*gray*/
  --color5: #464877;/*gray1*/
  --color6: #CFD0DA;/*gray2*/
  --color7: #E7EAF7;/*gray3*/
  --color8: #C54040;/*red*/
}


@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Regular.woff2') format('woff2'),
        url('../fonts/Montserrat-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
     -webkit-font-smoothing: antialiased;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Bold.woff2') format('woff2'),
        url('../fonts/Montserrat-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
     -webkit-font-smoothing: antialiased;
}

.mnt-container {
	font-family: 'Montserrat';
	background: url('../images/BG.png');
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 100vh;
	padding-bottom: 100px;
}
.ant-typography.mnt-color-blue1 {
	color: var(--color1);
}
.ant-typography.mnt-color-blue2 {
	color: var(--color1);
}

.mnt-container-div {
	margin-top: 75px;
} 
.mnt-container-logo-cont img {
	width: 115px;
}

.mnt-container-content {
	margin-top: 15px;
	min-height: 420px;
	border: solid 1px #dadce5;
	border-radius: 8px;
}

.mnt-container-content-step {
	background-color: rgba(240, 244, 252,0.9);
	border-right: solid 1px #dadce5;
	padding-left: 25px;
	padding-top: 14px;
}
.mnt-container-content-step-org-cont {
	padding-top: 20px;
	height: 200px;
}

.mnt-container-content-step-org-cont .ant-steps-item-title {
	font-size: 13px;
}

.ant-steps-item-container {
	height: 60px;
}
.mnt-mobile-page{
	background-color:#ffffffd6;
}
.mnt-mobile-page-content {
	padding:  30px 60px;
}

.mnt-input .ant-input {
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom: 2px solid var(--color1);
	background-color: transparent;
}
.ant-inp-suffix .ant-input {
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom: 2px solid #7c7ea3;
}
.mnt-input-cnt .ant-input-affix-wrapper, .mnt-input .ant-input-affix-wrapper {
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom: 2px solid var(--color1);
	padding-left: 0px;
}

.mnt-input .ant-input-group-addon {
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom: 2px solid var(--color1);
	background-color: transparent;
	color: var(--color1);
}

.mnt-input .ant-input-wrapper .ant-input:focus {
	border-color:  var(--color1);
    box-shadow: none;
    border-right-width: 0px;
    outline: 0;
} 

.mnt-input .ant-input-wrapper .ant-input:hover {
    border-color: var(--color1) !important;
}

.mnt-input .ant-input-affix-wrapper:focus, .mnt-input .ant-input-affix-wrapper-focused, .mnt-input-cnt .ant-input-affix-wrapper,.mnt-input-cnt .ant-picker,.mnt-input-cnt .ant-select-single{
    border-color:  var(--color1);
    box-shadow: none;
    border-right-width: 0px;
    outline: 0;
}

.mnt-input .ant-input-affix-wrapper:hover {
    border-color: var(--color1) !important;
}

.ant-select-selector {
	border: none !important;
	box-shadow: none !important;
}

.ant-select-arrow {
	color: var(--color1) !important;
}

.ant-select-single {
	border-top: none ;
	border-left: none;
	border-right: none;
	border-bottom: 2px solid  var(--color1);
	background-color: transparent;
}

.ant-select-single:hover {
	border-top: none ;
	border-left: none;
	border-right: none;
	border-bottom: 2px solid  var(--color1);
	background-color: transparent;
	
}

.ant-select-selector {
	border: none;
}

.mnt-input.ant-input {
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom: 2px solid  var(--color1) !important;
	background-color: transparent;
}

.mnt-input-cnt+.mnt-input-cnt {
	margin-top: 20px;
}
.mnt-input-cnt.mt-4{
	margin-top: 20px;
}
.check-box-tc{
	margin-top: 60px;
}
.mnt-btn-cnt-vrf{
	margin-top: 20px;
}
.mnt-input.ant-input:focus, .mnt-select {
    border-color: var(--color1);
    box-shadow: none;
    border-right-width: 0px;
    outline: 0;
}

.ant-btn.mnt-btn {
	border-radius: 20px;
	width: 100%;
}

.ant-btn.ant-btn-default.mnt-btn {
	color: #011af6;
	border-color: #011af6;
}

.ant-btn.ant-btn-primary.mnt-btn {
	background-color: #011af6;
	border-color: #011af6;
}
.ellips-style{
	text-overflow: ellipsis;
	overflow: hidden;
}

.ant-btn.ant-btn-primary.mnt-btn:hover {
	background-color: #fff;
	color: #3141f2;
}

.ant-btn.ant-btn-default.mnt-btn:hover {
	background-color: #3141f2;
	color: #fff;
}

.mnt-mobile-page-content .mnt-input {
	display: block;
}

.mnt-container-logo-cnct {
	text-align: right;
}

.mnt-mobile-page-content .mnt-btn-cnt {
	margin-top: 50px;
}
.mnt-otp-ui {
	margin-top: 25px;	
}
.mnt-otp-ui input {
	width: 30px !important;
	height: 30px !important;
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom: 2px solid var(--color1);
}

.mnt-container-content .ant-steps-item-tail::after {
	background-color: #03064d !important;
}

.mnt-container-content .ant-steps-item-finish .ant-steps-item-icon {
	border-color: #03064d;
}

.mnt-container-content .ant-steps-item-finish .ant-steps-icon {
	color: #03064d !important;
}

.mnt-container-content .ant-steps-item-process .ant-steps-item-icon {
	border-color: #03064d;
	background-color: #03064d !important;
}
.mnt-container-content .ant-inp-suffix{
    display: inline-flex;
    padding: 4px 11px;
}
.ant-input-suffix{
	font-size: 12px;
}

.mnt-btn-cnt-or {
	margin-top: 10px;
	margin-bottom: 10px;
	text-align: center;
}

.ant-typography.errorClass {
	color:  var(--color8);
	font-size: 12px;
}

.ant-input-affix-wrapper-disabled {
	background: white !important;
	color: var(--color1) !important;
}
.ant-input-affix-wrapper-disabled .ant-input.ant-input-disabled{
	color: var(--color1) !important;
}
.ant-steps-item-active .ant-steps-item-content:after {
	content: '';
	width: 7px;
	height: 7px;
	background-color: #27de8e;
	border-radius: 7px;
	display: inline-block;
	margin-left: -10px;
	margin-top: 5px;
	}

.motion-disabled .ant-result {
	padding: 0 0 0 0 !important;
}

.mnt-mobile-page-content .ant-result-success svg{
	fill:#4ae0a3
}

.mnt-mobile-page-content .ant-result-info svg{
	fill:#0a3cf1
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .mnt-container-div {
	margin-top: 15px;
   } 
   .mnt-container-content-step-org-cont {
	    padding-top: 0px; 
	    height: auto;
	}
	.mnt-mobile-hidden {
		display: none;
	}
	.mnt-container-content-step-org-cont .ant-steps-item {
		display: none !important;
	}
	.mnt-container-content-step-org-cont .ant-steps-item.ant-steps-item-active {
		display: block !important;
	}

	.mnt-container-content-step-org-cont .ant-steps-item.ant-steps-item-active .ant-steps-item-tail {
		display: none !important;
	}
	.ant-steps.ant-steps-vertical.mnt-container-content-step-org {
		align-items: flex-end;
	}
	.mnt-mobile-page-content {
		padding:  30px;
	}
	.mnt-container-content {
		min-height: auto;
		border: none;
	}
}
